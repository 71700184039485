.button {
    border: 2px solid $lightest-grey;
    border-radius: 8px;
    padding: $small-margin-padding;
    margin: $small-margin-padding;
    background-color: transparent;
    color: $lightest-grey;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
        background-color: $lightest-grey;
        color: $light-black;
    }

    &:hover .text-color-lightest-grey {
        color: $light-black;
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;

        &:hover {
            background-color: $light-black;
            color: $lightest-grey;
            border: 2px solid $lightest-grey;
            border-radius: 8px;
        }
    }
}

.box {
    margin: $small-margin-padding $small-margin-padding $small-margin-padding $small-margin-padding;
    padding: $small-margin-padding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: $small-margin-padding;
    border: 1px solid $light-grey;

    .box-account {
        height: 30%;
    }

    .box-text-left {
        flex: 1;
        text-align: left;
    }

    .box-text-right {
        flex: 1;
        text-align: right;
    }
}

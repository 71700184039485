.spinner {
    margin-left: auto;
    margin-right: auto;
    border: 4px solid #f3f3f3;
    border-top: 4px solid purple;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

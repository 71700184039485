$gutter: 1em;
$small-font-size: 12px;
$medium-font-size: 16px;
$large-font-size: 24px;
$small-margin-padding: 8px;
$medium-margin-padding: 16px;
$large-margin-padding: 24px;
$x-large-margin-padding: 48px;

// Colors
$light-black: #272727;
$grey: #888;
$light-grey: #ccc;
$white: #fff;
$lightest-grey: #eee;

@import 'pages/design-system/Box/box';
@import 'pages/design-system/Button/button';
@import 'pages/design-system/Column/column';
@import 'pages/design-system/Container/container';
@import 'pages/design-system/Input/input';
@import 'pages/design-system/Line/line';
@import 'pages/design-system/Spinner/spinner';
@import 'pages/design-system/Text/text';
@import 'pages/design-system/Toast/toast';

@mixin clearfix() {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin grid-row {
    @include clearfix;
}

body {
    margin: 0;
}

.app {
    background-color: $light-black;
}

.page-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.main-content {
    flex: 1;
}

.margin-right {
    margin-right: $small-margin-padding;

    &.margin-right-medium {
        margin-right: $medium-margin-padding;
    }

    &.margin-right-large {
        margin-right: $large-margin-padding;
    }

    &.margin-right-x-large {
        margin-right: $x-large-margin-padding;
    }
}

.row {
    @include grid-row;
}

.mobile-mint-detail-col {
    @media (max-width: 740px) {
        width: 100%;
    }
}

.mobile-mint-art-col {
    @media (max-width: 740px) {
        width: 100%;
    }
}

.account-container {
    margin-left: $small-margin-padding;
    margin-right: $small-margin-padding;
    padding: $small-margin-padding;
    background-color: $lightest-grey;
}

.account-copy {
    margin: $medium-margin-padding $small-margin-padding $medium-margin-padding $small-margin-padding;
    padding: $small-margin-padding;
    background-color: $lightest-grey;
}

.account-header {
    margin-left: $small-margin-padding;
    padding-bottom: $small-margin-padding;
}

.header {
    margin-left: $medium-margin-padding;
    margin-right: $medium-margin-padding;
    padding-top: $medium-margin-padding;
    padding-left: $medium-margin-padding;
    padding-right: $medium-margin-padding;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-container {
    @media (max-width: 767px) {
        display: none;
    }
}

.clickable-div {
    cursor: pointer;
}

.box-details {
    margin: $small-margin-padding $small-margin-padding $small-margin-padding $small-margin-padding;
    border-radius: $small-margin-padding;
    border: 1px solid $light-grey;
    padding: $small-margin-padding;
}

.inline-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inline-container-no-justify {
    display: flex;
    align-items: center;
}

.account-connected {
    border: 2px solid $lightest-grey;
    border-radius: 8px;
    padding: $small-margin-padding;
    margin: $small-margin-padding;
    background-color: transparent;
    color: $lightest-grey;
    font-size: 16px;
    font-weight: 600;
}

.small-margin {
    margin: $small-margin-padding;
    margin-bottom: $small-margin-padding;
    padding-bottom: $small-margin-padding;
    padding-left: $small-margin-padding;
    padding-right: $small-margin-padding;
}

.medium-margin {
    margin: $medium-margin-padding;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: $medium-margin-padding;
    padding-bottom: $medium-margin-padding;
}

.center-items {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mint-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $small-margin-padding;
    margin-bottom: $small-margin-padding;
}

.mint-button {
    width: 100%;
}

.mint-input {
    width: 100%;
    background-color: transparent;
    font-size: $medium-font-size;
    color: $lightest-grey;
}

.mobile-desc {
    @media (max-width: 800px) {
        display: none;
    }
}

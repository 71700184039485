.toast {
    background-color: #d75b58;
    opacity: 0.8;
    color: #fff;
    padding: 1rem;
    border-radius: 5px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 1000;
    cursor: pointer;

    &__message {
        margin: 0;
        padding: 0.25rem 1rem;
    }

    &__close-button {
        background-color: transparent;
        border: none;
        color: #fff;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        cursor: pointer;
    }
}

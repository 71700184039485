@mixin grid-column {
    float: left;

    &:last-child {
        margin-right: 0;
    }
}

.col {
    @include grid-column;

    &.col-25 {
        width: 30%;

        @media (max-width: 800px) {
            width: 100%;
        }
    }

    &.col-50 {
        width: 50%;
    }

    &.col-75 {
        width: 70%;

        @media (max-width: 800px) {
            width: 100%;
        }
    }
}

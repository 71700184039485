.input {
    border: 2px solid $lightest-grey;
    border-radius: 8px;
    padding: 10px;
    margin: $small-margin-padding;
    background-color: transparent;
    color: $lightest-grey;
    font-size: 16px;
    font-weight: 600;
}

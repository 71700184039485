@mixin clearfix() {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin grid-container {
    width: 100%;
    margin: 0 auto;

    @include clearfix;

    @media (max-width: 767px) {
        float: none;
        max-width: 100%;
    }
}

.container {
    @include grid-container;
}

@font-face {
    font-family: nue-hass-grotesk-display-pro;
    src: url('/fonts/NeueHaasDisplayBlack.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@mixin text {
    font-family: nue-hass-grotesk-display-pro, sans-serif;
}

.text {
    @include text;

    &.text-color-black {
        color: black;
    }

    &.text-color-white {
        color: $white;
    }

    &.text-color-grey {
        color: $grey;
    }

    &.text-color-light-grey {
        color: $light-grey;
    }

    &.text-color-lightest-grey {
        color: $lightest-grey;
    }

    &.text-color-light-black {
        color: $light-black;
    }

    &.text-size-16px {
        font-size: $medium-font-size;

        @media (max-width: 740px) {
            font-size: 12px;
        }
    }

    &.text-size-24px {
        font-size: $large-font-size;

        @media (max-width: 740px) {
            font-size: 14px;
        }
    }

    &.text-size-12px {
        font-size: $small-font-size;

        @media (max-width: 740px) {
            font-size: 10px;
        }
    }

    &.text-weight-semi-bold {
        font-weight: 600;
    }

    &.text-weight-normal {
        font-weight: 400;
    }
}
